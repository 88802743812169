<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div style="height:10px"></div>
      <div data-v-4169b9a4="" class="cate">
        <span v-for="item in rowsData[0].arr" @click="setCurrentItem(item)" :key="item.label" :class="item.label==title?'selected':''">{{item.label}} <em class="red">{{item.Odds}}</em> </span>

      </div>
      <!-- 球球-->
      <div class="player listmain type-sxg lgx5 game-lgx5 game-sxg-kx">

        <div class="data cZ1">
          <h3>第一球</h3>
          <ul class="custom_ul">
            <li :class="{selected:item.selected,disable:item.disable}" @click="setCurrentSelected(item,0,[2,3])" v-for="item in numArr1" :key="item.number">
              <ol class="td_name">
                <div :class="'blueball b'+item.label"></div>
              </ol>
            </li>

          </ul>
        </div>
        <div class="data cZ2">
          <h3>第二球</h3>
          <ul class="custom_ul">
            <li :class="{selected:item.selected,disable:item.disable}" @click="setCurrentSelected(item,1,[1,3])" v-for="item in numArr2" :key="item.number">
              <ol class="td_name">
                <div :class="'blueball b'+item.label"></div>
              </ol>
            </li>

          </ul>
        </div>
        <div class="data cZ3" v-show="titleNum==3">
          <h3>第三球</h3>
          <ul class="custom_ul">
            <li :class="{selected:item.selected,disable:item.disable}" @click="setCurrentSelected(item,2,[1,2])" v-for="item in numArr3" :key="item.number">
              <ol class="td_name">
                <div :class="'blueball b'+item.label"></div>
              </ol>
            </li>

          </ul>
        </div>

      </div>
      <div class="player listmain tuoduopeng ">
        <div class="data">
          <h3>注单号码<span>共 <em class="red">{{jcpTotalNum}}</em> 种组合 </span></h3>

          <!-- <div class="dantuo-li">
            
          </div> -->
          <div class="dantuo-sc  red">
            <div>
              <span v-for="(item,index) in jcpZuHeData" :key="index">[{{item.join(',')}}]</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 预设 -->
      <yushe />
      <!-- - -->
      <!-- 表格 -->
      <div class="roadmap">
        <table class="thlottab">
          <tbody>
            <tr>
              <td :class="qiuIndex==1?'selected':''" @click="changeQiu(1)">第一球</td>
              <td :class="qiuIndex==2?'selected':''" @click="changeQiu(2)">第二球</td>
              <td :class="qiuIndex==3?'selected':''" @click="changeQiu(3)">第三球</td>
              <td :class="qiuIndex==4?'selected':''" @click="changeQiu(4)">第四球</td>
              <td :class="qiuIndex==5?'selected':''" @click="changeQiu(5)">第五球</td>
            </tr>
          </tbody>
        </table>
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th :class="ltIndex==1?'selected':''" @click="changeLutuData(1)">单双</th>
              <th :class="ltIndex==2?'selected':''" @click="changeLutuData(2)">大小</th>
              <th :class="ltIndex==3?'selected':''" @click="changeLutuData(3)">龙虎</th>
              <th :class="ltIndex==4?'selected':''" @click="changeLutuData(4)">总和单双</th>
              <th :class="ltIndex==5?'selected':''" @click="changeLutuData(5)">总和大小</th>
            </tr>
          </tbody>
        </table>

        <table colspan="0" id="lt_ds" cellspan="0" class="lttab">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span :class="fontColor[ele]">{{ele}}</span>

              </td>

            </tr>

          </tbody>
        </table>

        <!-- --- -->
      </div>
      <!--  -->

    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "syx5zhixuan",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'zx',
      ltArr: [],
      title: "",
      titleId: "",
      titleNum: "",
      titleOdds: "",
      numArr1: [
        { label: "01", selected: false, number: 1, disable: false },
        { label: "02", selected: false, number: 2, disable: false },
        { label: "03", selected: false, number: 3, disable: false },
        { label: "04", selected: false, number: 4, disable: false },
        { label: "05", selected: false, number: 5, disable: false },
        { label: "06", selected: false, number: 6, disable: false },
        { label: "07", selected: false, number: 7, disable: false },
        { label: "08", selected: false, number: 8, disable: false },
        { label: "09", selected: false, number: 9, disable: false },
        { label: "10", selected: false, number: 10, disable: false },
        { label: "11", selected: false, number: 11, disable: false },
      ],
      numArr2: [
        { label: "01", selected: false, number: 1, disable: false },
        { label: "02", selected: false, number: 2, disable: false },
        { label: "03", selected: false, number: 3, disable: false },
        { label: "04", selected: false, number: 4, disable: false },
        { label: "05", selected: false, number: 5, disable: false },
        { label: "06", selected: false, number: 6, disable: false },
        { label: "07", selected: false, number: 7, disable: false },
        { label: "08", selected: false, number: 8, disable: false },
        { label: "09", selected: false, number: 9, disable: false },
        { label: "10", selected: false, number: 10, disable: false },
        { label: "11", selected: false, number: 11, disable: false },
      ],
      numArr3: [
        { label: "01", selected: false, number: 1, disable: false },
        { label: "02", selected: false, number: 2, disable: false },
        { label: "03", selected: false, number: 3, disable: false },
        { label: "04", selected: false, number: 4, disable: false },
        { label: "05", selected: false, number: 5, disable: false },
        { label: "06", selected: false, number: 6, disable: false },
        { label: "07", selected: false, number: 7, disable: false },
        { label: "08", selected: false, number: 8, disable: false },
        { label: "09", selected: false, number: 9, disable: false },
        { label: "10", selected: false, number: 10, disable: false },
        { label: "11", selected: false, number: 11, disable: false },
      ],
      rowsData: [{ title: "直选", arr: [] }],
      labelArr: ["前二直选", "前三直选"],
      bzArr: [2, 3],
      //  交叉碰
      jcRows: [[], []],
      jcpTotalNum: 0,
      jcpZuHeData: [], //交叉碰组合数据
      lutu1: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },

      lutu2: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu3: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu4: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu5: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
    };
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    jcRows: {
      deep: true,
      handler() {
        this.watchJCP();
      },
    },
    amount() {
      this.watchJCP();
    },
    oddData1: {
      immediate: true,
      deep: true,
      handler(resArr) {
        let arr = this.filterData(resArr, 1059, 1060, this.labelArr);
        arr.forEach((item, index) => {
          item.bzNum = this.bzArr[index];
        });
        this.rowsData[0].arr = arr;
        if (this.zxActive.ResultID) {
          this.getCurrentItem();
        } else {
          this.title = this.rowsData[0].arr[0].label;
          this.titleId = this.rowsData[0].arr[0].ResultID;
          this.titleNum = this.rowsData[0].arr[0].bzNum;
          this.titleOdds = this.rowsData[0].arr[0].recordOdds;

          // console.log(this.titleOdds, 222222);
          this.jcpZuHeData = [];
          this.jcpTotalNum = 0;
          this.jcRows = [];
          for (let index = 0; index < this.titleNum; index++) {
            this.jcRows.push([]);
          }
        }
      },
    },
  },
  created() {},
  computed: {
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },

  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu5 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            // 第一球----------
            let arr1_ds = [[]],
              arr1_dx = [[]],
              // 第二球
              arr2_ds = [[]],
              arr2_dx = [[]],
              // 第三球
              arr3_ds = [[]],
              arr3_dx = [[]],
              // 第四球
              arr4_ds = [[]],
              arr4_dx = [[]],
              // 第五球
              arr5_ds = [[]],
              arr5_dx = [[]],
              // ------------------
              arr_zh = [[]],
              arr_lh = [[]],
              arr_zhds = [[]],
              arr_zhdx = [[]];
            // --------------
            let list = resObj.data;
            list.reverse()
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");
              // -第一球------------------------------------------------
              //  单双
              let q1 = element.codeArr[0];
              let ds1 = "";
              if (q1 == 11) {
                ds1 = "和";
              } else {
                ds1 = q1 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr1_ds, ds1);
              // 大小
              let dx1 = "";
              if (q1 == 11) {
                dx1 = "和";
              } else {
                dx1 = q1 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr1_dx, dx1);
              //  第二球--------------- ---------------------------------
              let q2 = element.codeArr[1];
              let ds2 = "";
              if (q2 == 11) {
                ds2 = "和";
              } else {
                ds2 = q1 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr2_ds, ds2);
              // 大小
              let dx2 = "";
              if (q2 == 11) {
                dx2 = "和";
              } else {
                dx2 = q2 >= 5 ? "大" : "小";
              }
              this.initlutuData(arr2_dx, dx2);
              //  第三球--------------- ---------------------------------
              let q3 = element.codeArr[2];
              let ds3 = "";
              if (q3 == 11) {
                ds3 = "和";
              } else {
                ds3 = q3 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr3_ds, ds3);
              // 大小
              let dx3 = "";
              if (q3 == 11) {
                dx3 = "和";
              } else {
                dx3 = q3 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr3_dx, dx3);
              //  第四球--------------- ---------------------------------
              let q4 = element.codeArr[3];
              let ds4 = "";
              if (q4 == 11) {
                ds4 = "和";
              } else {
                ds4 = q4 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr4_ds, ds4);
              // 大小
              let dx4 = "";
              if (q4 == 11) {
                dx4 = "和";
              } else {
                dx4 = q4 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr4_dx, dx4);
              //  第五球--------------- ---------------------------------
              let q5 = element.codeArr[4];
              let ds5 = "";
              if (q5 == 11) {
                ds5 = "和";
              } else {
                ds5 = q5 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr5_ds, ds5);
              // 大小
              let dx5 = "";
              if (q5 == 11) {
                dx5 = "和";
              } else {
                dx5 = q5 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr5_dx, dx5);
              // 龙虎
              let lh =
                Number(element.codeArr[0]) - Number(element.codeArr[4]) > 0
                  ? "龙"
                  : "虎";
              this.initlutuData(arr_lh, lh);

              // 总和
              let sum = 0;
              element.codeArr.forEach((num) => {
                sum += Number(num);
              });
              // this.initlutuData(arr_zh, sum);
              // 总和单双大小
              let sumds = sum % 2 == 0 ? "双" : "单";
              let sumdx = "";
              if (sum == 30) {
                sumdx = "和";
              } else if (sum > 30) {
                sumdx = "大";
              } else {
                sumdx = "小";
              }

              this.initlutuData(arr_zhds, sumds);
              this.initlutuData(arr_zhdx, sumdx);
              // ----------------------------------------------------------
            });
            // 第一球 单双 大小 总和
            this.buquanArr(arr1_ds, this.lutu1.arr1);
            this.buquanArr(arr1_dx, this.lutu1.arr2);
            // 第二球 单双 大小 总和
            this.buquanArr(arr2_ds, this.lutu2.arr1);
            this.buquanArr(arr2_dx, this.lutu2.arr2);
            // 第三球 单双 大小 总和
            this.buquanArr(arr3_ds, this.lutu3.arr1);
            this.buquanArr(arr3_dx, this.lutu3.arr2);
            // 第四球 单双 大小 总和
            this.buquanArr(arr4_ds, this.lutu4.arr1);
            this.buquanArr(arr4_dx, this.lutu4.arr2);
            // 第五球 单双 大小 总和
            this.buquanArr(arr5_ds, this.lutu5.arr1);
            this.buquanArr(arr5_dx, this.lutu5.arr2);
            // 龙虎---------------------------------
            this.buquanArr(arr_lh, this.lutu1.arr3);
            this.buquanArr(arr_lh, this.lutu2.arr3);
            this.buquanArr(arr_lh, this.lutu3.arr3);
            this.buquanArr(arr_lh, this.lutu4.arr3);
            this.buquanArr(arr_lh, this.lutu5.arr3);

            this.buquanArr(arr_zhds, this.lutu1.arr4);
            this.buquanArr(arr_zhds, this.lutu2.arr4);
            this.buquanArr(arr_zhds, this.lutu3.arr4);
            this.buquanArr(arr_zhds, this.lutu4.arr4);
            this.buquanArr(arr_zhds, this.lutu5.arr4);

            this.buquanArr(arr_zhdx, this.lutu1.arr5);
            this.buquanArr(arr_zhdx, this.lutu2.arr5);
            this.buquanArr(arr_zhdx, this.lutu3.arr5);
            this.buquanArr(arr_zhdx, this.lutu4.arr5);
            this.buquanArr(arr_zhdx, this.lutu5.arr5);
            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          } else {
            this.ltArr = [];
          }
        } else {
          this.ltArr = [];
        }
      });
    },
    multiplyData(newArrData) {
      let len = newArrData.length;
      // 当数组长度大于等于2时
      if (len >= 2) {
        // 第一个数组的长度
        let len1 = newArrData[0].length;
        // 第二个数组的长度
        let len2 = newArrData[1].length;
        //  申明一个新数组,做数据暂存
        let items = new Array();
        // 申明新数组的索引
        let index = 0;
        // 2层嵌套循环,将组合放到新数组中
        for (var i = 0; i < len1; i++) {
          for (var j = 0; j < len2; j++) {
            if (items.length < index + 1) {
              items.push([]);
            }
            if (newArrData[0][i] instanceof Array) {
              items[index] = items[index].concat(
                newArrData[0][i].concat(newArrData[1][j])
              );
            } else {
              items[index] = items[index].concat([
                newArrData[0][i],
                newArrData[1][j],
              ]);
            }
            index++;
          }
        }
        // 将新组合的数组并到原数组中
        let newArr = new Array(len - 1);
        for (let i = 2; i < newArrData.length; i++) {
          newArr[i - 1] = newArrData[i];
        }
        newArr[0] = items;
        // 执行回调
        return this.multiplyData(newArr);
      } else {
        // console.log(newArrData[0]);
        return newArrData[0];
      }
    },
    watchJCP() {
      let b = this.jcRows.every((item) => item.length > 0);
      this.jcpTotalNum = 0;
      this.jcpZuHeData = [];
      if (b) {
        let newArr = this.multiplyData(this.jcRows);
        let AllLabelArr = [];
        this.jcpTotalNum = 0;
        newArr.forEach((item) => {
          this.jcpTotalNum++;
          let arr = [];
          item.forEach((ele) => {
            arr.push(ele.label);
          });
          AllLabelArr.push(arr);
        });
        this.jcpZuHeData = AllLabelArr;
        let xiazhu = [];
        AllLabelArr.forEach((item) => {
          let obj = {
            label: item.join(","),
            num: item.join(","),
            title: this.title,
            id: this.titleId,
            money: this.amount,
            Odds: this.titleOdds,
          };
          xiazhu.push(obj);
        });

        this.$store.commit("setXZList", xiazhu);
      } else {
        this.$store.commit("setXZList", []);
      }
    },
    setCurrentSelected(item, jcIndex, keyArr) {
      if (item.disable) {
        return;
      }
      item.selected = !item.selected;
      keyArr.forEach((num) => {
        let ele = this["numArr" + num].find((ele) => ele.number == item.number);
        ele.disable = item.selected;
      });
      if (item.selected) {
        this.jcRows[jcIndex].push(item);
      } else {
        let Index = this.jcRows[jcIndex].findIndex(
          (ele) => ele.number == item.number
        );
        this.jcRows[jcIndex].splice(Index, 1);
      }
      // console.log(this.jcRows);
      this.$forceUpdate();
    },
    setCurrentItem(item) {
      this.$store.commit("SETZHIXUANACTIVE", item);
      this.title = item.label;
      this.titleId = item.ResultID;
      this.titleOdds = item.Odds;
      this.titleNum = item.bzNum;
      this.jcpZuHeData = [];
      this.jcpTotalNum = 0;
      this.jcRows = [];
      for (let index = 0; index < this.titleNum; index++) {
        this.jcRows.push([]);
      }
      // console.log(this.jcRows);
      this.numArr1.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.numArr2.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.numArr3.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
    },
    getCurrentItem() {
      this.title = this.zxActive.label;
      this.titleId = this.zxActive.ResultID;
      this.titleOdds = this.zxActive.Odds;
      this.titleNum = this.zxActive.bzNum;
      this.jcpZuHeData = [];
      this.jcpTotalNum = 0;
      this.jcRows = [];
      for (let index = 0; index < this.titleNum; index++) {
        this.jcRows.push([]);
      }
      // console.log(this.jcRows);
      this.numArr1.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.numArr2.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.numArr3.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
    },
  },
};
</script>
<style  scoped>
.main .cate span.selected .red {
  color: rgb(246, 1, 1);
  font-weight: bold;
}

.listmain .data ul li {
  flex: 1;
}
.listmain .data ul.custom_ul li .td_name {
  width: 100%;
}
</style>